<template>
  <div class="home">
    <Login class="login"  />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'Home',
  components: {
    Login
  }
}
</script>
<style>
  .login {
    width: 400px;
    margin: auto;
    height: 450px;
    margin-top: 20vh;

  }

</style>
