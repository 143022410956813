<template>
  <div>
    <div class="ti">
    <el-select v-model="school" placeholder="请选择学校"  @change="school_change">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select v-model="xt" placeholder="请选择子系统"  @change="xt_change">
      <el-option
        v-for="item in options_xt"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div style="width:100%">
      <img style="width:100%" v-if="ticket!=''" :src="`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=`+ticket">
    </div>
    </div>

  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
export default {
  name: "Login",

  data() {
    return {
      school: "",
      options: [
        {
          value: "wxc66c054a1c53bc0d",
          label: "沈阳师范大学",
        },
         {
          value: "wx4ad912d73cf3e655",
          label: "大连海洋大学",
        },
        {
          value:'wxb65caab3877a72af',
          label:'辽宁中医药大学'
        }
      ],
       options_xt: [
        {
          value: "学工系统",
          label: "学工系统",
        },
         {
          value: "迎新系统",
          label: "迎新系统",
        },
         {
          value: "寝室系统",
          label: "寝室系统",
        }
      ],
      xt:'迎新系统',
      uuid:'',
      fullscreenLoading: false,
      ticket:'',
      openid:'',
    };
  },
  methods:{
    school_change(e){
      this.school = e
      this.uuid = uuid.v1()
      const loading = this.$loading({
          lock: true,
          text: '正在获取登陆二维码',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });



        this.$axios.get('https://timkj.cn/api/wechat/CreateLoginQr?appid='+this.school+'&code='+this.uuid).then((res)=>{
          var data = res.data
          if(data.code == 1){
            //成功
            console.log(data)
            var ticket = data.data.ticket;
            this.ticket = ticket
            this.startGetStatus()
          }else{
            this.$message.info(data.msg)
          }
          })

      loading.close();
    },
    xt_change(e){
      this.xt = e

    },
    startGetStatus(){
      //开始循环获取状态
      var i =  setInterval(() => {
         this.$axios.get('https://timkj.cn/api/wechat/GetLoginStatus?code='+this.uuid).then((res)=>{
          var data = res.data
          if(data.code == 1){
            //成功
            console.log(data)
            var openid = data.data
            this.openid = openid;
            clearInterval(i)
            this.onSuccess()

          }else{
           console.log(data.msg)
          }
          }).catch(()=>{
            clearInterval(i)
          })
      }, 2000);
    },
    //获取成功后 进行进行分系统跳转
    //TODO openid获取成功后 默认进行请求后端 并进行二次跳转
    //特殊情况当前直接进行跳转直接使用code 与 openid 进行timkj.cn校验 校验完成后 完成寝室系统登录
    onSuccess(){
      if(!this.xt){
        this.$message.error('请选择登录子系统')
        return false;
      }

      switch(this.xt){
        case "学工系统":
            this.$axios.get('https://timkj.cn/api/user/DoAdminLogin?openid='+this.openid+'&code='+this.uuid).then((res)=>{
        var data = res.data
        if(data.code == 1){
            var token = data.data.token
            var fa_token = data.data.token.token
            window.localStorage.setItem('fa_token',fa_token);
            window.localStorage.setItem('fa_user_info',JSON.stringify(token));
            //TODO 进行跳转
            this.$router.push({
                path:'Index'
            })
            // window.location.href = 'BuildingMap'


        }else{
          this.$message.info(data.msg)
        }
      }).catch((e)=>{
        this.$message.error('网络异常,请稍后重试')
      })
      break;
      case "寝室系统":
        this.$axios.get('https://auth.api.timkj.cn/api/dorm/DoAdminLogin?openid='+this.openid+'&code='+this.uuid).then((res)=>{
        var data = res.data
        if(data.code == 1){
            var token = data.data.token
            var fa_token = data.data.token.token
            window.localStorage.setItem('fa_token',fa_token);
            window.localStorage.setItem('fa_user_info',JSON.stringify(token));
            //TODO 进行跳转
            this.$router.push({
                path:'BuildingMap'
            })
            // window.location.href = 'BuildingMap'


        }else{
          this.$message.info(data.msg)
        }
      }).catch((e)=>{
        this.$message.error('网络异常,请稍后重试')
      })
      break;
      case "迎新系统":
        window.location.href = 'https://yingxin.timkj.cn/i.php/index/DoAdminLogin?openid='+this.openid+'&code='+this.uuid;

         break;
      }



    }

  },
  created(){
      window.localStorage.clear();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.ti {
  width:90%;
  display:grid;
  margin-top: 2vh;
}
</style>
