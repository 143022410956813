import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import UUID from 'vue-uuid'
Vue.use(UUID)
require('promise.prototype.finally').shim();
Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
