<template>
  <div class="index">
    <div>{{token}}</div>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Index',
  data(){
      return {
          token:''
      }
  },
  created(){
      console.log(window.localStorage.getItem('fa_token'))
        this.token = window.localStorage.getItem('fa_token');
  }
}
</script>
<style>


</style>
