import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import FormConfig from '../views/FormConfig.vue'
import Index from '../views/Index.vue'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/BuildingMap',
        name: 'BuildingMap',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/BuildingMap.vue')
    },
    {
        path: '/FormConfig',
        name: 'FormConfig',
        component: FormConfig
    },
    {
        path: '/Index',
        name: 'Index',
        component: Index
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router