<template>
  <div class="home">
    <el-form
      ref="form"
     
      :model="form_config"
      label-position="left"
    >
      <el-form-item label="名称">
        <el-input v-model="form_config.name" disabled></el-input>
      </el-form-item>
      <el-form-item v-if="auth_type != '辅导员'" label="学院">
        <el-select
          style="width: 100%"
          v-model="form_config.xy"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in xy_list"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="auth_type != '辅导员'" label="人员类型">
        <el-select
          style="width: 100%"
          v-model="form_config.man_class"
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in man_class"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="周期">
        <el-select
          style="width: 100%"
          v-model="form_config.zq"
          placeholder="请选择"
        >
          <el-option
            v-for="item in zq_list"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="form_config.zq != '无' && form_config.zq != null"
        label="周期值"
      >
        <el-input
          v-model="form_config.zqz"
          placeholder="请填写周期值"
        ></el-input>
      </el-form-item>
      <el-form-item v-else label="无周期总可提交次数(0为不限制)">
        <el-input
          v-model="form_config.submit_count"
          placeholder="请填写总可提交次数"
        ></el-input>
      </el-form-item>
      <el-form-item label="开始时间">
        <el-date-picker
          style="width: 100%"
          v-model="form_config.start_time"
          value-format="timestamp"
          type="datetime"
          placeholder="选择开始时间"
          align="right"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          style="width: 100%"
          v-model="form_config.end_time"
          value-format="timestamp"
          type="datetime"
          placeholder="选择结束时间"
          align="right"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="auth_type == '辅导员'" label="班级">
        <el-select
          style="width: 100%"
          v-model="form_config.class_id"
          clearable
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in class_list"
            :key="item.class_id"
            :label="
              item.class_name + '/' + item.class_grade + '/' + item.class_zy
            "
            :value="item.class_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="auth_type == '辅导员'" label="学生">
        <el-select
          style="width: 100%"
          v-model="form_config.man_id"
          clearable
          filterable
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in student"
            :key="item.man_id"
            :label="
              item.class_name + '/' + item.man_name + '/' + item.man_number
            "
            :value="item.man_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "FormConfig",
  data() {
    return {
      token: "",
      formid: "",
      form_config: {
        zq: "无",
      },
      xy_list: [],
      man_class: [],
      auth_type: "",
      zq_list: ["无", "N天", "N小时", "N周", "N月"],
      class_list: [],
      student: [],
      
    };
  },
  methods: {
    submit(e) {
         if(!this.form_config.start_time){
             this.$message.info('请选择开始时间')
             return false;
         }
         if(!this.form_config.end_time){
             this.$message.info('请选择结束时间')
             return false;
         }
         if(this.auth_type == '辅导员'){
             console.log(this.form_config.class_id)
             if(this.form_config.class_id == '' ){
                  this.$message.info('您必须选择一个班级1')
                  return false;
             }
             if(this.form_config.class_id == null){
                 
                  this.$message.info('您必须选择一个班级2')
                  return false;
             }
             if(this.form_config.class_id == undefined){
                  this.$message.info('您必须选择一个班级3')
                  return false;
             }
         }
      this.$axios
        .post(
          "https://timkj.cn/index/form_config/setapi",
          {
            ...this.form_config,
          },
          { headers: { token: this.token } }
        )
        .then((res) => {
            if(res.data.code  == 0){
                this.$message.success('更新成功')
                window.location.href = 'http://audi.web.timkj.cn/#/pages/collect/collect?formid='+this.formid+'&token='+this.token;
            }else{
                this.$message.info('更新失败')
            }
        }).catch((res)=>{
             this.$message.info('异常:'+JSON.stringifyre(res))
        })
    },
  },
  created() {
    this.token = this.$route.query.token;

    this.formid = this.$route.query.formid;

    var that = this;
    //TODO  确认当前人员权限 并在前段限制 若人员权限为 辅导员  则必须关联班级信息 否则无法发出
    //TODO  若当前人员权限为校级、院级 则只能发送全学院的信息收集
    this.$axios
      .post(
        "https://timkj.cn/index/form_config/configapi",
        {
          id: this.formid,
        },
        { headers: { token: this.token } }
      )
      .then((res) => {
        this.form_config = res.data.data;
        if (this.form_config.start_time != null) {
          this.form_config.start_time = this.form_config.start_time * 1000;
        }
        if (this.form_config.end_time != null) {
          this.form_config.end_time = this.form_config.end_time * 1000;
        }
        this.auth_type = res.data.auth;
        if (this.auth_type == "辅导员") {
          //辅导员方案 直接查询对应的班级
          console.log("开始查询班级");
          // getclasslist
          this.$axios
            .post(
              "https://timkj.cn/index/form_config/getclasslist",
              {
                type: this.form_config.form_type,
              },
              { headers: { token: this.token } }
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.class_list = res.data.class;
                this.student = res.data.student;
              } else {
                that.$message.info(res.data.msg);
              }
            })
            .catch(() => {
              that.$message.error("获取班级数据失败,请重试");
            });
        } else {
          //非辅导员方案 直接显示学院 专业 人员类型表  后续 类型 需要整合man_class 与 student_class
          this.$axios
            .post(
              "https://timkj.cn/index/form_config/getXyList",
              {
                type: this.form_config.form_type,
              },
              { headers: { token: this.token } }
            )
            .then((res) => {
              this.man_class = res.data.data.man_class;
              console.log(this.man_class);
              this.xy_list = res.data.data.xy_list;
            })
            .catch(() => {
              that.$message.error("获取基础配置信息失败,请重试");
            });
        }
      })
      .catch(() => {
        that.$message.error("获取基础配置信息失败,请重试");
      });
  },
};
</script>
